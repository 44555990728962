<template>
   <LoginDetail />
</template>

<script>
import LoginDetail from "@/base/core/components/authentication/login/LoginDetail.vue"
export default {
  name: "Login",
  components: {
  LoginDetail
  },
  
};
</script>

