<template>
  <div>
    <section class="auth-page login">
      <div class="container">
        <div class="row">
          <div class="col col-md-8 mx-auto desktop">
            <h3 class="semiBold-22 text-center auth-title">
              {{ $t("login") }}
            </h3>
          </div>
          <div class="col col-md-12">
            <div class="row justify-content-center auth-box-row">
              <div class="col col-md-6 col-lg-4">
                <div class="auth-box">
                  <h4 class="auth-box-title semiBold-15">
                    {{ $t("new_customers") }}
                  </h4>
                  <p class="regular-12">{{ $t("register_info") }}</p>
                  <div class="text-right">
                    <router-link :to="{ name: 'signUp' }">
                      <button class="c-btn c-btn-primary">
                        {{ $t("create_account") }}
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col col-md-6 col-lg-4">
                <div class="auth-box">
                  <h3 class="semiBold-22 auth-title mobile">
                    {{ $t("login") }}
                  </h3>
                  <h4 class="auth-box-title semiBold-15">
                    {{ $t("registered_customers") }}
                  </h4>
                  <p class="regular-12">{{ $t("account_login") }}</p>
                  <b-form @submit.prevent="onLoginFormSubmit" novalidate>
                    <div class="auth-form-wrap">
                      <div class="auth-form-inner">
                        <b-form-group
                          class="account-inputs"
                          id="email-group"
                          :label="$t('form_email') + '*'"
                          label-for="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="loginData.email"
                            type="text"
                            :state="loginValidation.emailState"
                            @blur="validateEmail"
                            :placeholder="$t('email_placeholder')"
                            aria-describedby="email-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="email-feedback">
                            {{ loginValidation.emailError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                          class="account-inputs password-input"
                          id="password-group"
                          :label="$t('form_password') + '*'"
                          label-for="password"
                        >
                          <b-form-input
                            id="password"
                            v-model="loginData.password"
                            type="password"
                            :state="loginValidation.passwordState"
                            @blur="validatePassword"
                            :placeholder="$t('password_placeholder')"
                            aria-describedby="password-feedback"
                          ></b-form-input>
                          <b-form-invalid-feedback id="password-feedback">
                            {{ loginValidation.passwordError }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <!-- <div
                          class="form-group c-input-item"
                          :class="{
                            error: !loginValidation.email.valid,
                            'error-warning': !loginValidation.email.valid,
                          }"
                        >
                          <label for="" class="light-12"
                            >{{ $t("form_email")
                            }}<sup class="text-danger">*</sup></label
                          >
                          <input
                            type="email"
                            class="c-input-filed light-12"
                            :placeholder="$t('email_placeholder')"
                            v-model="loginData.email"
                          />
                          <small
                            v-if="!loginValidation.email.valid"
                            class="form-error"
                            >{{ loginValidation.email.msg }}</small
                          >
                        </div>
                        <div
                          class="form-group c-input-item"
                          :class="{
                            error: !loginValidation.password.valid,
                            'error-warning': !loginValidation.password.valid,
                          }"
                        >
                          <label for="" class="light-12"
                            >{{ $t("form_password")
                            }}<sup class="text-danger">*</sup></label
                          >
                          <input
                            type="password"
                            class="c-input-filed light-12"
                            :placeholder="$t('password_placeholder')"
                            v-model="loginData.password"
                          />
                          <small
                            v-if="!loginValidation.password.valid"
                            class="form-error"
                            >{{ loginValidation.password.msg }}</small
                          >
                        </div> -->
                      </div>
                      <div class="button-links">
                        <div
                          class="
                            d-flex
                            flex-wrap
                            justify-content-between
                            align-items-end
                          "
                        >
                          <p class="mb-0">
                            <router-link
                              :to="{ name: 'forgotPassword' }"
                              class="regular-12"
                              ><u>{{ $t("password_forgot_login_form") }}</u>
                            </router-link>
                          </p>
                          <button class="c-btn c-btn-primary" type="submit">
                            {{ $t("login") }}
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="auth-form-wrap">
                      <div class="auth-form-inner">
                        <div
                          class="form-group c-input-item"
                          :class="{
                            error: !loginValidation.email.valid,
                            'error-warning': !loginValidation.email.valid,
                          }"
                        >
                          <label for="" class="light-12"
                            >{{ $t("form_email")
                            }}<sup class="text-danger">*</sup></label
                          >
                          <input
                            type="email"
                            class="c-input-filed light-12"
                            :placeholder="$t('email_placeholder')"
                            v-model="loginData.email"
                          />
                          <small
                            v-if="!loginValidation.email.valid"
                            class="form-error"
                            >{{ loginValidation.email.msg }}</small
                          >
                        </div>
                        <div
                          class="form-group c-input-item"
                          :class="{
                            error: !loginValidation.password.valid,
                            'error-warning': !loginValidation.password.valid,
                          }"
                        >
                          <label for="" class="light-12"
                            >{{ $t("form_password")
                            }}<sup class="text-danger">*</sup></label
                          >
                          <input
                            type="password"
                            class="c-input-filed light-12"
                            :placeholder="$t('password_placeholder')"
                            v-model="loginData.password"
                          />
                          <small
                            v-if="!loginValidation.password.valid"
                            class="form-error"
                            >{{ loginValidation.password.msg }}</small
                          >
                        </div>
                      </div>
                      <div class="button-links">
                        <div
                          class="
                            d-flex
                            flex-wrap
                            justify-content-between
                            align-items-end
                          "
                        >
                          <p class="mb-0">
                            <router-link
                              :to="{ name: 'forgotPassword' }"
                              class="regular-12"
                              ><u>{{ $t("password_forgot_login_form") }}</u>
                            </router-link>
                          </p>
                          <button
                            class="c-btn c-btn-primary"
                            @click="validateForm"
                          >
                            {{ $t("login") }}
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Login from "@storefront/core/modules/user/mixins/login";
import { Logger } from "@storefront/core/lib/logger";
import config from "@config";

export default {
  name: "Login",
  mixins: [Login],

  data() {
    return {
      loginValidation: {
        emailState: null,
        emailError: "",
        passwordState: null,
        passwordError: "",
        // valid: true,
        // email: {
        //   valid: true,
        //   msg: "",
        // },
        // password: {
        //   valid: true,
        //   msg: "",
        // },
      },
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  methods: {
    onLoginFormSubmit() {
      let formValid = true;
      if (this.loginValidation.emailState != true) {
        this.validateEmail();
        if (this.loginValidation.emailState != true) {
          formValid = false;
        }
      }
      Logger.debug("onLoginFormSubmit", "email", formValid)();

      if (this.loginValidation.passwordState != true) {
        this.validatePassword();
        if (this.loginValidation.passwordState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        this.login();
      }
    },

    // validateForm() {
    //   this.loginValidation.valid = true;
    //   if (this.loginData.email == "") {
    //     this.loginValidation.valid = false;
    //     this.loginValidation.email.valid = false;
    //     this.loginValidation.email.msg = this.$t("email_is_required_field");
    //     // this.loginValidation.email.msg = "Field is required.";
    //   } else if (!this.emailReg.test(this.loginData.email)) {
    //     this.loginValidation.valid = false;
    //     this.loginValidation.email.valid = false;
    //     this.loginValidation.email.msg = this.$t("email_is_invalid_field");
    //     // this.loginValidation.email.msg = "Please enter valid email.";
    //   } else {
    //     this.loginValidation.email.valid = true;
    //     this.loginValidation.email.msg = "";
    //   }
    //   if (this.loginData.password == "") {
    //     this.loginValidation.valid = false;
    //     this.loginValidation.password.valid = false;
    //     this.loginValidation.password.msg = this.$t(
    //       "password_is_required_field"
    //     );
    //     // this.loginValidation.password.msg = "Field is required.";
    //   } else {
    //     this.loginValidation.password.valid = true;
    //     this.loginValidation.password.msg = "";
    //   }

    //   if (this.loginValidation.valid) {
    //     this.login();
    //   }
    // },

    validateEmail() {
      if (this.loginData.email.length == 0) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.loginData.email)) {
        this.loginValidation.emailState = false;
        this.loginValidation.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.loginValidation.emailState = true;
      return true;
    },

    validatePassword() {
      if (this.loginData.password.length == 0) {
        this.loginValidation.passwordState = false;
        this.loginValidation.passwordError = this.$t(
          "password_is_required_field"
        );
        return false;
      }
      this.loginValidation.passwordState = true;
      return true;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.$t("login"),
        },
        { name: "keywords", content: this.$t("login") },
        { name: "description", content: this.$t("login") },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t("login"),
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
};
</script>

<style></style>
